<template>
  <div>
    <el-row type="flex" justify="end">
      <el-button type="primary" size="small" @click="(isEdit = false), (isDisable = false), (dialogVisible = true)">新增</el-button>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="已发布" name="1"></el-tab-pane>
      <el-tab-pane label="已下架" name="2"></el-tab-pane>
    </el-tabs>

    <el-table :data="tableData" style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="title" label="农产品名称"> </el-table-column>
      <!-- <el-table-column prop="lighting" label="亮点"> </el-table-column> -->
      <el-table-column prop="shortDesc" label="描述" show-overflow-tooltip> </el-table-column>

      <el-table-column prop="salePrice" label="价格">
        <template slot-scope="scope"> {{ scope.row.salePrice / 100 }}元 /{{ scope.row.priceUnit }}</template>
      </el-table-column>
      <!-- <el-table-column prop="priceUnit" label="单位"> </el-table-column> -->
      <el-table-column prop="expertName" label="地址" show-overflow-tooltip>
        <template slot-scope="scope">
          <div
            >{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.county }}{{ scope.row.township }}{{ scope.row.village
            }}{{ scope.row.address }}</div
          >
        </template>
      </el-table-column>
      <el-table-column prop="expertName" label="联系人">
        <template slot-scope="scope">
          <div>{{ scope.row.contractName }}</div>
          <div>{{ scope.row.contractPhone }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="publishTime" label="发布时间"> </el-table-column>

      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-popconfirm v-if="activeName == 1" title="确认下架吗？下架后该产品将不再展示?" @confirm="toUnpublish(scope.row)">
            <el-button slot="reference" type="text" size="small">下架</el-button>
          </el-popconfirm>
          <el-popconfirm v-if="activeName == 2" title="确认上架吗？上架后该产品将重新展示在客户端?" @confirm="toPublish(scope.row)">
            <el-button slot="reference" type="text" size="small">上架</el-button>
          </el-popconfirm>
          <el-button type="text" size="small" @click="toDetail(scope.row)">详情</el-button>
          <el-button v-if="activeName == 2" type="text" size="small" @click="toEdit(scope.row)">修改</el-button>
          <el-popconfirm v-if="activeName == 2" title="确认删除吗？" @confirm="toDelete(scope.row)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <create
      :dialogVisible.sync="dialogVisible"
      :dicAll="dicAll"
      :isDisabled="isDisable"
      :isEdit="isEdit"
      :currentId="currentId"
      @updateList="getList"
    ></create>
  </div>
</template>

<script>
import { supplyListAgriculture, publishSupplyAgriculture, unpublishSupplyAgriculture, deleteSupplyAgriculture } from '@/api/api.js';
import create from './create.vue';
export default {
  components: {
    create
  },
  data() {
    return {
      dicAll: {},
      dialogVisible: false,
      isDisable: false,
      isEdit: false,
      expertName: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      questionType: '',
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '1',

      tableData: [],
      options: [],
      questionTypeDic: null
    };
  },
  created() {},
  async mounted() {
    this.getList();
    let res = await this.$store.dispatch('getDicAll');

    this.dicAll = res;
  },
  methods: {
    toDelete(item) {
      deleteSupplyAgriculture({ id: item.id })
        .then((res) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    toUnpublish(item) {
      unpublishSupplyAgriculture({ id: item.id })
        .then((res) => {
          this.$message({
            message: '取消发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    toPublish(item) {
      publishSupplyAgriculture({ id: item.id })
        .then((res) => {
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    search() {
      this.searchParams = {
        expertName: this.expertName,
        questionType: this.questionType,
        questionDateMin: this.applytDate && this.applytDate[0] ? dayjs(this.applytDate[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        questionDateMax: this.applytDate && this.applytDate[1] ? dayjs(this.applytDate[1]).format('YYYY-MM-DD HH:mm:ss') : ''
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toDetail(item) {
      this.currentId = item;
      this.isDisable = true;
      this.dialogVisible = true;
    },
    toEdit(item) {
      this.isDisable = false;
      this.currentId = item;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      supplyListAgriculture({
        state: this.activeName,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
