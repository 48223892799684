<template>
  <el-dialog title="农产品发布" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules" :disabled="isDisabled" label-width="120px">
      <el-form-item label="农产品名称" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <!-- <el-form-item label="亮点" prop="lighting">
        <el-input v-model="form.lighting"></el-input>
      </el-form-item> -->
      <el-form-item label="所属种类" prop="commodityType">
        <el-select v-model="form.commodityType" placeholder="请选择">
          <el-option v-for="item in commodityTypeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单价(元)" prop="salePrice">
        <el-input-number :precision="2" :controls="false" :disabled="isDisabled" v-model="form.salePrice"></el-input-number>
      </el-form-item>
      <el-form-item label="价格单位" prop="priceUnit">
        <el-input :disabled="isDisabled" v-model="form.priceUnit"></el-input>
      </el-form-item>
      <el-form-item label="数量" prop="amountDesc">
        <el-input maxlength="10" :disabled="isDisabled" v-model="form.amountDesc"></el-input>
      </el-form-item>
      <el-form-item label="照片">
        <el-upload
          :class="{ hideUpload: hideUploadEdit }"
          action=""
          ref="upload"
          :file-list="fileList"
          :limit="1"
          list-type="picture-card"
          accept="image/jpg,image/jpeg,image/png"
          :auto-upload="false"
          :on-change="fileChange"
          :on-remove="fileChange"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <!-- <el-form-item label="服务商" prop="contractName">
        <el-autocomplete
          class="inline-input"
          v-model="form.contractName"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          @select="handleSelect"
        >
          <template slot-scope="{ item }">
            <div class="name">{{ item.name }}</div>
          </template>
        </el-autocomplete>
      </el-form-item> -->
      <el-form-item label="服务商" prop="contractName">
        <el-select
          v-model="form.servCustomerId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="loading"
          @change="supplyChange"
        >
          <el-option v-for="item in options" :key="item.id + 'select'" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="contractPhone">
        <el-input v-model="form.contractPhone"></el-input>
      </el-form-item>
      <el-form-item label="选择地址">
        <el-row>
          <div v-if="mapInfo"> {{ mapInfo.pname }}/{{ mapInfo.cityname }}/{{ mapInfo.adname }}/{{ mapInfo.address }} </div>
          <el-button type="primary" @click="mapVisible = true">选择地址</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="短介绍" prop="shortDesc">
        <el-input type="textarea" autosize v-model="form.shortDesc"></el-input>
      </el-form-item>
      <el-form-item label="详细描述" prop="content">
        <el-input type="textarea" autosize v-model="form.content"></el-input>
      </el-form-item>

      <el-form-item v-if="!isDisabled">
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="closeMe">取消</el-button>
      </el-form-item>
    </el-form>

    <mapContainerVue :mapVisible.sync="mapVisible" @select="mapSelect"></mapContainerVue>
  </el-dialog>
</template>

<script>
import { createSupplyAgriculture, updateSupplyAgriculture, supplyAgricultureDetail, getRequest } from '@/api/api';
import mapContainerVue from '@/components/mapContainer.vue';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: '',
    isDisabled: false,
    dicAll: {}
  },
  components: {
    mapContainerVue
  },
  computed: {
    commodityTypeList() {
      let list = this.dicAll['agriculture.commodity.type'];
      let options1 = [];
      for (const key in list) {
        if (Object.hasOwnProperty.call(list, key)) {
          const e = list[key];
          options1.push({ id: Number(key), name: e });
        }
      }

      return options1;
    }
  },
  data() {
    return {
      hideUploadEdit: false,
      fileList: [],
      mapVisible: false,
      mapInfo: '',
      form: {
        title: '',
        contractPhone: '',
        contractName: '',
        amountDesc: '大量',
        salePrice: '',
        priceUnit: '',
        lighting: '',
        address: '',
        shortDesc: '',
        commodityType: '',
        content: '',
        servCustomerId: ''
      },
      rules: {},
      servCustomerId: '',
      loading: false,
      options: []
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.hideUploadEdit = false;
        this.mapInfo = '';
        this.form = {
          title: '',
          contractPhone: '',
          contractName: '',
          amountDesc: '大量',
          salePrice: '',
          priceUnit: '',
          lighting: '',
          address: '',
          shortDesc: '',
          commodityType: '',
          content: ''
        };
        this.fileList = [];
        if (this.isEdit || this.isDisabled) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    supplyChange(id) {
      let item = this.options.find((e) => {
        return e.id == id;
      });
      // this.servCustomerId = item.id;
      this.form.contractName = item.juridicalPerson;
      let mapI = {
        pname: item.province,
        location: {
          lng: item.lon,
          lat: item.lat
        },
        cityname: item.city,
        adname: item.county
      };
      this.mapInfo = mapI;
      this.form.address = item.address;
      // this.form.content = item.descriptions;
      this.form.contractPhone = item.contract;
    },

    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        getRequest('/api/operate/govserv/agriculture/supply/customer/page', { pageSize: 999, name: query })
          .then((res) => {
            this.loading = false;
            this.options = res.rows;
          })
          .catch((error) => {
            this.loading = false;
            this.options = [];
          });
      } else {
        this.options = [];
      }
    },
    // 校验手机号
    validatePhone(rule, value, callback) {
      let msg = '';
      value = value && value.toString().replace(/\s+/g, '');
      if (!value) {
        msg = '请输入手机号码！';
      } else {
        let reg = /^[0-9]+.?[0-9]*/;
        if (!reg.test(value)) {
          msg = '请输入正确的手机号码！';
        }
      }
      if (msg) {
        callback(new Error(msg));
      } else {
        callback();
      }
    },
    mapSelect(e) {
      this.mapVisible = false;
      if (e) {
        this.mapInfo = e;
        this.form.address = e.name;
      }
    },
    getDetail() {
      supplyAgricultureDetail({ id: this.currentId.id })
        .then((e) => {
          // this.getOptionList(e.servCustomerId);
          this.options = [e.customer];
          this.form = _.cloneDeep(e);
          // this.form.priceUnit = this.form.priceUnit / 100;
          this.form.salePrice = this.form.salePrice / 100;
          let res = _.cloneDeep(e);
          let mapI = {
            pname: res.province,
            location: {
              lng: res.lon,
              lat: res.lat
            },
            cityname: res.city,
            adname: res.county
          };
          this.mapInfo = mapI;
          this.fileList = [{ name: '1', url: res.titleImgUrl }];
          if (this.fileList && this.fileList.length) {
            this.hideUploadEdit = true;
          }
        })
        .catch((error) => {});
    },

    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
      this.fileList = fileList;
    },
    closeMe() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      let msg = '';
      if (!this.mapInfo) {
        msg = '请选择地址';
      }
      if (!msg && (!this.fileList || this.fileList.length == 0)) {
        msg = '请选择图片';
      }
      if (msg) {
        this.$message({
          message: msg,
          type: 'warning'
        });
      } else {
        let form = _.cloneDeep(this.form);
        let params = {};

        // form.priceUnit = form.priceUnit * 100;
        form.salePrice = form.salePrice * 100;
        params.province = this.mapInfo.pname;
        params.city = this.mapInfo.cityname;
        params.county = this.mapInfo.adname;
        params.lon = this.mapInfo.lng;
        params.lat = this.mapInfo.lat;
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let formData = new FormData();
            if (this.fileList && this.fileList.length && this.fileList[0].raw) {
              formData.append('file', this.fileList[0].raw);
            }
            formData.append('requestJson', JSON.stringify({ ...form, ...params }));

            if (this.isEdit) {
              updateSupplyAgriculture(formData)
                .then((e) => {
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  });
                  this.closeMe();
                  this.updateList();
                })
                .catch((error) => {});
            } else {
              createSupplyAgriculture(formData)
                .then((e) => {
                  this.$message({
                    message: '创建成功',
                    type: 'success'
                  });
                  this.closeMe();
                  this.updateList();
                })
                .catch((error) => {});
            }
          }
        });
      }
    },

    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 300px;
}
</style>
